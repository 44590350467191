var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',{staticClass:"info-content"},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-n2 mb-3 mx-6 font-weight-medium",attrs:{"append-icon":"search","label":"Procurar / filtrar","text":"","single-line":"","hide-details":"","clearable":"","color":"blue darken-3"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},on))]}}])},[_c('span',[_vm._v(" Filtrar ")])]),_c('v-data-table',{staticClass:"content-table",attrs:{"headers":_vm.headers,"items":_vm.lista,"search":_vm.search,"footer-props":_vm.footer_props},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Ainda não há instrumentos deste tipo carregados na plataforma.")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium my-3",attrs:{"value":true,"color":"error","icon":"warning","id":"alerta-erro"}},[_vm._v("Não foram encontrados resultados para \""+_vm._s(_vm.search)+"\".")])]},proxy:true},{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.data))]),_c('td',[_vm._v(_vm._s(props.item.tipo))]),_c('td',[_c('a',{attrs:{"href":'/legislacao/' + props.item.idLeg}},[_vm._v(_vm._s(props.item.numero))])]),_c('td',[(props.item.tipoEntidade == 'Entidade')?_c('span',[_c('a',{attrs:{"href":'/entidades/ent_' + props.item.entidade}},[_vm._v(_vm._s(props.item.entidade)+" (Entidade)")])]):_c('span',[_c('a',{attrs:{"href":'/tipologias/tip_' + props.item.entidade}},[_vm._v(_vm._s(props.item.entidade)+" (Tipologia)")])])]),_c('td',[_vm._v(_vm._s(props.item.sumario))]),_c('td',[_vm._v(_vm._s(props.item.estado))]),_c('td',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('ver', props.item.idRADA, _vm.tipo)}}},[_c('v-icon',[_vm._v("remove_red_eye")])],1),(_vm.tipo == 'RadaAntigo' && props.item.link.startsWith('CLAV'))?_c('v-btn',{attrs:{"text":"","href":_vm.pathAPI +
                '/ficheirosEstaticos?caminho=documentos%2FRADA%2FDespacho' +
                props.item.link.split('Despacho')[1] +
                '&' +
                _vm.authToken}},[_c('v-icon',{attrs:{"color":"#c62828"}},[_vm._v("picture_as_pdf")])],1):_c('v-btn',{attrs:{"text":""},on:{"dblclick":function($event){return _vm.$emit('download', props.item.idRADA, _vm.tipo)},"click":function($event){return _vm.debug(props.item.link)}}},[_c('v-icon',{attrs:{"color":"#c62828"}},[_vm._v("picture_as_pdf")])],1)],1)])]}},{key:"item.entidades",fn:function(ref){
                var item = ref.item;
return _vm._l((item.entidades),function(ent,index){return _c('span',{key:index},[_vm._v(_vm._s(ent))])})}},{key:"pageText",fn:function(props){return [_vm._v(" Resultados: "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }